/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';

if (typeof window !== `undefined`) {
    // Resolve
    require('bootstrap/dist/js/bootstrap.bundle.min.js');
  }