module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/assets/images/zipteam-icon.png","defaults":{"quality":90,"formats":["auto","webp","avif"]},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4ddea34e3f17aa2bda70c273a23d37ac"},
    },{
      plugin: require('../node_modules/gatsby-plugin-addsocialshare-share/gatsby-browser.js'),
      options: {"plugins":[],"size":30,"corners":"50%","bgcolor":"#426af0","interfacetype":"floating","topoffset":"70%","id":".ass_interface","alignment_desktop":"bottom","alignment_mobile":"bottom"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://zipteam.wpenginepowered.com/graphql","baseUrl":"https://zipteam.wpenginepowered.com/graphql","concurrentRequests":10,"protocol":"https","hostingWPCOM":false,"useACF":true,"verboseOutput":true,"perPage":100,"options":{"schema":{"perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5}},"searchAndReplaceContentUrls":{"sourceUrl":"https://zipteam.wpenginepowered.com/graphql","replacementUrl":""},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
